import React, { Component } from 'react';
import { Layout } from '../../components';

class TeamOrders extends Component {
  componentDidMount() {
    const { location } = this.props;
    const { search } = location;
    window.location.href = `https://${process.env.YORDAR_APP_URL}/team_orders/team_order_show_menu${search}`;
  }

  render() {
    const { location } = this.props;
    return (
      <Layout location={location}>
        <div className="wrapper not-found-wrap redirection-page">
          <div className="not-found-text">
            <h2>Redirecting, please wait...</h2>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TeamOrders;
